<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="title">금속검출 현황</h5>
        <div class="input_text">
          <label for="">조회일자</label>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="selectDate"
            :max="maxDate"
            @change="setDate($event)"
          />
        </div>
      </div>
      <h6>조회수 : {{ getMetalLog.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>조회시간</th>
              <th>Lot 번호</th>
              <th>제품명</th>
              <th>진행수</th>
              <th>양품</th>
              <th>불량</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getMetalLog" :key="item.id">
              <td>{{ item.time | formatDateHHMMSS }}</td>
              <td>
                {{ item.lotNum }} &nbsp;
                <button class="btn_tbl" @click="trackingLotNum(item.lotNum)">
                  추적
                </button>
              </td>
              <td>{{ item.productName }}</td>
              <td>
                {{
                  $makeComma(
                    $decimalAdd($makeNumber(item.pass), $makeNumber(item.fail)),
                  )
                }}
              </td>
              <td class="pass">
                {{ item.pass == null ? 0 : $makeComma(item.pass) }}
              </td>
              <td class="poor">
                {{ item.fail == null ? 0 : $makeComma(item.fail) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import routes from '@/routes/routes';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      maxDate: yyyymmdd(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      counter_logs_day: 'getCounterLogDay',
      selectDate: 'getSelectDateFromPlcCounterLog',
      lots: 'getLot',
      products: 'getProduct',
      plc_sensor_detail: 'getSensorDetailTypes',
    }),
    getMetalLog() {
      let searchLog = this.lodash
        .clonedeep(this.counter_logs_day)
        .filter(x => x.plc_sensor_id == 26 || x.plc_sensor_id == 27);
      let metalLog = [];

      if (searchLog.length > 0) {
        searchLog = searchLog.sort(
          (b, a) =>
            Number(
              a.end_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.end_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        );
        searchLog.forEach((el, index) => {
          if (index % 2 != 0) {
            const lotNum = this.findInfoFromId(this.lots, el.lot_id).lot_number;
            const product = this.findInfoFromId(this.products, el.product_id);
            let passCount = null;
            let failCount = null;
            if (
              this.findInfoFromId(
                this.plc_sensor_detail,
                el.plc_sensor_detail_type_id,
              ).detail == 'pass count'
            ) {
              passCount = el.value;
              failCount = searchLog[index - 1].value;
            } else if (
              this.findInfoFromId(
                this.plc_sensor_detail,
                el.plc_sensor_detail_type_id,
              ).detail == 'fail count'
            ) {
              passCount = searchLog[index - 1].value;
              failCount = el.value;
            }

            metalLog.push({
              time: el.end_time,
              lotNum: lotNum,
              productName: product.name,
              standard: product.standard,
              pass: passCount,
              fail: failCount,
            });
          }
        });
      }
      return metalLog;
    },
  },
  methods: {
    async setDate(e) {
      this.$store.commit('setSelectDateToPlcCounterLog', e.target.value);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_COUNTER_LOG_DAY',
        this.selectDate,
        '금속검출기현황',
      );
    },
    trackingLotNum(lotNum) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/tracking',
      );
      if (goingPage != undefined) {
        goingPage.meta.select = Number(lotNum.substr(8, 5));
      }
      this.$router.push('/product/tracking');
    },
  },
  async created() {
    if (this.plc_sensor_detail.length < 1)
      await this.FETCH('FETCH_PLC_SENSOR_DETAIL_TYPE', 'PLC 상세타입');

    if (this.lots.length < 1) await this.FETCH('FETCH_LOT_ALL', '작업지시');

    if (this.products.length < 1) await this.FETCH('FETCH_PRODUCT', '제품');

    if (this.selectDate == null) {
      this.$store.commit('setSelectDateToPlcCounterLog', this.maxDate);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_COUNTER_LOG_DAY',
        this.selectDate,
        '금속검출기현황',
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
