<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="title">포장 현황</h5>
        <div class="input_text">
          <label for="">조회일자</label>
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="selectDate"
            :max="maxDate"
            @change="setDate($event)"
          />
        </div>
      </div>
      <h6>조회수 : {{ getPackageLog.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>조회시간</th>
              <th>Lot 번호</th>
              <th>제품명</th>
              <th>진행수</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getPackageLog" :key="item.id">
              <td>{{ item.end_time | formatDateHHMMSS }}</td>
              <td>
                {{ findInfoFromId(lots, item.lot_id).lot_number }} &nbsp;
                <button
                  class="btn_tbl"
                  @click="
                    trackingLotNum(findInfoFromId(lots, item.lot_id).lot_number)
                  "
                >
                  추적
                </button>
              </td>
              <td>{{ findInfoFromId(products, item.product_id).name }}</td>
              <td>
                {{ item.value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import routes from '@/routes/routes';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      maxDate: yyyymmdd(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      counter_logs_day: 'getCounterLogDay',
      selectDate: 'getSelectDateFromPlcCounterLog',
      lots: 'getLot',
      products: 'getProduct',
      plc_sensor_detail: 'getSensorDetailTypes',
    }),
    getPackageLog() {
      let searchLog = this.lodash
        .clonedeep(this.counter_logs_day)
        .filter(x => x.plc_sensor_id == 28);

      if (searchLog.length > 0) {
        searchLog = searchLog.sort(
          (b, a) =>
            Number(
              a.start_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.start_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        );
      }
      return searchLog;
    },
  },
  methods: {
    async setDate(e) {
      this.$store.commit('setSelectDateToPlcCounterLog', e.target.value);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_COUNTER_LOG_DAY',
        this.selectDate,
        '포장 현황',
      );
    },
    trackingLotNum(lotNum) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/tracking',
      );
      if (goingPage != undefined) {
        goingPage.meta.select = Number(lotNum.substr(8, 5));
      }
      this.$router.push('/product/tracking');
    },
  },
  async created() {
    if (this.plc_sensor_detail.length < 1)
      await this.FETCH('FETCH_PLC_SENSOR_DETAIL_TYPE', 'PLC 상세타입');

    if (this.lots.length < 1) await this.FETCH('FETCH_LOT_ALL', '작업지시');

    if (this.products.length < 1) await this.FETCH('FETCH_PRODUCT', '제품');

    if (this.selectDate == null) {
      this.$store.commit('setSelectDateToPlcCounterLog', this.maxDate);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_COUNTER_LOG_DAY',
        this.selectDate,
        '포장 현황',
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
