var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status_board_jincheon",class:{
    plc_th_status: _vm.tabIndex == 0,
    dehumidifier_status: _vm.tabIndex == 3,
    default_setting: _vm.tabIndex == 5,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToPlcMonitorPage',"options":[
          { title: '온습도 현황', name: 'th_status' },
          { title: '금속검출 현황', name: 'metal_detection_status' },
          { title: '포장 현황', name: 'package_status' },
          { title: '제습기 현황', name: 'dehumidifier_status' },
          { title: '중량계 현황', name: 'weighing_scale_status' },
          { title: '기준값 설정', name: 'default_setting' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:{ active: _vm.tabIndex == 0 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("온습도 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 1 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("금속검출 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 2 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("포장 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 3 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(3)}}},[_vm._v("제습기 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 4 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(4)}}},[_vm._v("중량계 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 5 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(5)}}},[_vm._v("기준값 설정")])])])]):_vm._e(),(_vm.tabIndex == 0)?_c('plc-th-status-form'):_vm._e(),(_vm.tabIndex == 1)?_c('metal-status-form'):_vm._e(),(_vm.tabIndex == 2)?_c('package-status-form'):_vm._e(),(_vm.tabIndex == 3)?_c('dehumidifier-status-form'):_vm._e(),(_vm.tabIndex == 4)?_c('weighing-scale-status-form'):_vm._e(),(_vm.tabIndex == 5)?_c('default-plc-form'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }