<template>
  <div
    id="contents"
    class="status_board_jincheon"
    :class="{
      plc_th_status: tabIndex == 0,
      dehumidifier_status: tabIndex == 3,
      default_setting: tabIndex == 5,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '온습도 현황', name: 'th_status' },
            { title: '금속검출 현황', name: 'metal_detection_status' },
            { title: '포장 현황', name: 'package_status' },
            { title: '제습기 현황', name: 'dehumidifier_status' },
            { title: '중량계 현황', name: 'weighing_scale_status' },
            { title: '기준값 설정', name: 'default_setting' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">금속검출 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">포장 현황</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">제습기 현황</a>
          </li>
          <li :class="{ active: tabIndex == 4 }">
            <a @click="SetOpenTabIndex(4)">중량계 현황</a>
          </li>
          <li :class="{ active: tabIndex == 5 }">
            <a @click="SetOpenTabIndex(5)">기준값 설정</a>
          </li>
        </ul>
      </div>
      <plc-th-status-form v-if="tabIndex == 0"></plc-th-status-form>
      <metal-status-form v-if="tabIndex == 1"></metal-status-form>
      <package-status-form v-if="tabIndex == 2"></package-status-form>
      <dehumidifier-status-form v-if="tabIndex == 3"></dehumidifier-status-form>
      <weighing-scale-status-form
        v-if="tabIndex == 4"
      ></weighing-scale-status-form>
      <default-plc-form v-if="tabIndex == 5"></default-plc-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import PlcThStatusForm from '@/views/forms/Custom/Monitor/11/PlcThStatusForm';
import MetalStatusForm from '@/views/forms/Custom/Monitor/11/MetalStatusForm';
import PackageStatusForm from '@/views/forms/Custom/Monitor/11/PackageStatusForm';
import DehumidifierStatusForm from '@/views/forms/Custom/Monitor/11/DehumidifierStatusForm';
import WeighingScaleStatusForm from '@/views/forms/Custom/Monitor/11/WeighingScaleStatusForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/11/DefaultPlcForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    PlcThStatusForm,
    MetalStatusForm,
    PackageStatusForm,
    DehumidifierStatusForm,
    WeighingScaleStatusForm,
    DefaultPlcForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'jincheon_monitoring';
</style>
