<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <span>조회시간: {{ str_time }}</span>
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <h5 class="title">제습기 현황</h5>
      </div>
      <div class="humi_wrap">
        <div
          class="humi_image"
          :class="{
            off: dehumi_action == '동작정지' || dehumi_action == '',
            on: dehumi_action != '동작정지' && dehumi_action != '',
          }"
        ></div>
        <ul>
          <li>
            동작상태 :
            <span
              :class="
                ({
                  off: dehumi_action == '동작정지',
                },
                `on_0${
                  dehumi_logs.find(x => x.plc_sensor_detail_type_id == 7).value
                }`)
              "
              >{{ dehumi_action }}</span
            >
          </li>
          <li>
            현재습도 :
            {{
              dehumi_logs.find(x => x.plc_sensor_name.includes('현재')).value
            }}
            %
          </li>
          <li>
            설정습도 :
            {{
              dehumi_logs.find(x => x.plc_sensor_name.includes('설정')).value
            }}
            %
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FETCH_MIXIN from '@/mixins/fetch';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      str_time: yyyy년MM월dd일HH시mm분ss초(new Date()),
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_tempHumi_logs: 'getTemperatureHumiditySensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    dehumi_logs() {
      let clone = this.lodash
        .clonedeep(this.plc_sensor_logs)
        .filter(
          x =>
            x.plc_location_name == '제습기' && x.plc_sensor_detail_type_id != 1,
        );
      return clone;
    },
    dehumi_action() {
      let clone = this.lodash
        .clonedeep(this.plc_sensor_logs)
        .filter(
          x =>
            x.plc_location_name == '제습기' && x.plc_sensor_detail_type_id == 7,
        );
      let action = '';
      switch (clone[0].value) {
        case 0:
          action = '동작정지';
          break;
        case 2:
          action = '난방중';
          break;
        case 3:
          action = '냉방중';
          break;
        case 4:
          action = '제습중';
          break;
        case 5:
          action = '송풍중';
          break;
        case 6:
          action = '청정';
          break;
        case 7:
          action = '자동';
          break;
        case 8:
          action = '가습중';
          break;
      }
      return action;
    },
  },
  methods: {
    // async refreshData() {
    //   await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
    //   this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    // },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
