<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <span>조회시간: {{ str_time }}</span>
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <h5 class="title">온습도 현황</h5>
      </div>
      <div class="mes_tbl_wrap" v-for="(n, index) in 2" :key="index">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>구분</th>
              <th>온도</th>
              <th>습도</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in index == 0
                ? grouped_temphumi_logs.slice(0, 5)
                : grouped_temphumi_logs.slice(5, grouped_temphumi_logs.length)"
              :key="item.id"
            >
              <td>
                {{ `${item.plc_location_name}: ${item.plc_sensor_type_name}` }}
              </td>
              <td>{{ item.value }}℃</td>
              <td>{{ item.humi_value }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FETCH_MIXIN from '@/mixins/fetch';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      str_time: yyyy년MM월dd일HH시mm분ss초(new Date()),
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_temperature_logs: 'getTemperatureSensorLog',
      plc_tempHumi_logs: 'getTemperatureHumiditySensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    grouped_temphumi_logs() {
      let clone = this.lodash.clonedeep(this.plc_tempHumi_logs);
      clone = clone.sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);
      let lists = [];
      let location_id_temp = null;
      let duplicate_count = 2;
      clone.forEach((element, index) => {
        if (index % 2 == 0 && element.plc_location_name != '제습기') {
          if (element.plc_location_id == location_id_temp) {
            element.plc_sensor_type_name =
              element.plc_sensor_type_name + duplicate_count;
            duplicate_count++;
          } else {
            duplicate_count = 2;
          }
          location_id_temp = element.plc_location_id;
          element.humi_value = clone[index + 1].value;
          lists.push(element);
        }
      });
      return lists;
    },
  },
  methods: {
    // async refreshData() {
    //   await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
    //   this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    // },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
